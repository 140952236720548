import React from "react";
import { ThemeProvider } from "styled-components";
import { getTheme } from "utils";

const themes = {
  blue: {
    mainColor: "#0058d7",
  },
  red: {
    mainColor: "#C93030",
  },
  green: {
    mainColor: "#389f3a",
  },
};

const Theme = ({ children }) => {
  const theme = getTheme();
  return <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>;
};

export default Theme;
