import { lazy } from "react";
import { Navigate } from "react-router-dom";

const BluePage = lazy(() => import("pages/BluePage"));
const GreenPage = lazy(() => import("pages/GreenPage"));
const RedPage = lazy(() => import("pages/RedPage"));

const routes = [
  {
    path: "/blue",
    element: <BluePage />,
  },
  {
    path: "/red",
    element: <RedPage />,
  },
  {
    path: "/green",
    element: <GreenPage />,
  },
  {
    path: "*",
    element: <Navigate to="/blue" />,
  },
];

export const getRoutes = () => routes;
